import { useMutation, useQueryClient } from "@tanstack/vue-query";
import type { components } from "~/schemas/contool";

export const USE_USER_MUTATION_KEY = "useUserMutation";
export type MutateUserParams = components["schemas"]["UserProfileUpdateDto"];
export type MutateUserResponse = components["schemas"]["UserProfile"];

export function useUserMutation() {
  const toast = useToast();
  const queryClient = useQueryClient();

  useSession(true);

  return useMutation({
    mutationKey: [USE_USER_MUTATION_KEY],
    mutationFn: async (updateParams: MutateUserParams) => {
      const req = await $fetch<MutateUserResponse>("/v1/user/me", {
        baseURL: BASE_URL,
        method: "PUT",
        body: updateParams,
      });

      return req;
    },

    onError: (err) => {
      toast.add({
        icon: "i-heroicons-x-mark",
        color: "red",
        title: "Error updating your profile",
        description: err.message,
      });
    },

    onSuccess: (res) => {
      queryClient.setQueryData<MutateUserResponse>(["useUser"], res);

      toast.add({
        icon: "i-heroicons-check-circle",
        color: "green",
        title: "User updated",
        description: "Your profile has been updated successfully.",
      });
    },
  });
}
