import { useMutation, useQueryClient } from "@tanstack/vue-query";

export const USE_USER_PFP_MUTATION_KEY = "useUserPfpMutation";

export function useUserPfpMutation() {
  const toast = useToast();
  const queryClient = useQueryClient();

  useSession(true);

  return useMutation({
    mutationKey: [USE_USER_PFP_MUTATION_KEY],
    mutationFn: async (file: File) => {
      const formData = new FormData();
      formData.append("file", file, "pfp.png");

      const req = await $fetch("/v1/user/me/avatar", {
        baseURL: BASE_URL,
        method: "PUT",
        body: formData,
      });

      return req;
    },

    onError: (err) => {
      toast.add({
        icon: "i-heroicons-x-mark",
        color: "red",
        title: "Error uploading profile picture",
        description: "An error occurred while uploading your profile picture.",
      });

      console.error(err);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["useUser"] });

      toast.add({
        icon: "i-heroicons-check-circle",
        color: "green",
        title: "Profile picture updated",
        description: "Your profile picture has been updated successfully.",
      });
    },
  });
}
