<script setup lang="ts">
import { useBase64, useFileDialog } from "@vueuse/core";

const props = defineProps({
  avatarUrl: {
    type: String,
    required: false,
    default: undefined,
  },
  userFullName: {
    type: String,
    required: false,
    default: undefined,
  },
  icon: {
    type: String,
    required: false,
    default: undefined,
  },
});

const { mutateAsync: uploadPfp, isPending: isUploadPending } =
  useUserPfpMutation();
const { mutateAsync: deletePfp, isPending: isDeletePending } =
  useUserPfpDeleteMutation();

const avatarMenuItems = computed(() => {
  const items = [
    [
      {
        label: "Upload a picture...",
        icon: "i-heroicons-arrow-up-tray-20-solid",
        click: open,
      },
    ],
  ];

  if (props.avatarUrl) {
    items[0].push({
      label: "Remove picture",
      icon: "i-heroicons-trash-20-solid",
      click: () => {
        showConfirmRemoveProfilePictureModal.value = true;
      },
    });
  }

  return items;
});

const showPreviewModal = ref(false);
const newPicturePreviewInBase64 = ref("");
const showConfirmRemoveProfilePictureModal = ref(false);

const { files, reset, open, onChange } = useFileDialog({
  multiple: false,
  accept: "image/*", // Set to accept only image files
});

onChange(async (files) => {
  /** do something with files */
  if (!files) return;
  showPreviewModal.value = true;
  newPicturePreviewInBase64.value = await useBase64(files[0]).promise.value;
});

watch(showPreviewModal, (value) => {
  if (!value) {
    reset();
    newPicturePreviewInBase64.value = "";
  }
});

const handleUploadNewProfilePicture = async () => {
  if (!files.value) return;

  try {
    await uploadPfp(files.value[0]);
    showPreviewModal.value = false;
  } catch (error) {
    console.error(error);
  }
};

const removeProfilePicture = async () => {
  try {
    await deletePfp();
    showConfirmRemoveProfilePictureModal.value = false;
  } catch (error) {
    console.error(error);
  }
};
</script>

<template>
  <u-dropdown :items="avatarMenuItems" :popper="{ placement: 'bottom-start' }">
    <u-avatar
      :src="avatarUrl"
      :alt="userFullName"
      class="hover:opacity-80 transition-opacity text-2xl"
      size="5xl"
      :icon="icon"
      img-class="object-cover"
    />

    <u-modal v-model="showPreviewModal">
      <u-card>
        <template #header>
          <h1 class="text-lg">Upload new picture</h1>
        </template>
        <div class="flex w-full items-center justify-center">
          <img
            :src="newPicturePreviewInBase64"
            class="w-60 h-60 object-cover rounded-full"
            :alt="userFullName"
          />
        </div>
        <template #footer>
          <u-button
            block
            :loading="isUploadPending"
            @click="handleUploadNewProfilePicture"
          >
            Set new profile picture
          </u-button>
        </template>
      </u-card>
    </u-modal>

    <u-modal v-model="showConfirmRemoveProfilePictureModal">
      <u-card>
        <template #header>
          <h1 class="text-lg">Do you want to remove your profile picture?</h1>
        </template>
        <span class="opacity-60">This action can't be undone.</span>
        <template #footer>
          <div class="flex justify-end space-x-2">
            <u-button
              color="white"
              @click="
                () => {
                  showConfirmRemoveProfilePictureModal = false;
                }
              "
            >
              No
            </u-button>
            <u-button
              :loading="isDeletePending"
              color="red"
              @click="removeProfilePicture"
            >
              Yes
            </u-button>
          </div>
        </template>
      </u-card>
    </u-modal>
  </u-dropdown>
</template>
